import {State} from '../../../types';

export const selectReservationData = (state: State) => {
    return state.calendar.reservationData;
};

export const selectReservationInfo = (state: State) => {
    return state.calendar.reservationInfo;
};

export const selectCalendarOpenHours = (state: State) => {
    return state.calendar.calendar.openHour;
};

export const selectShowReservationNew = (state: State) => {
    return state.calendar.calendar.showNewReservation;
};

export const selectShowTicketModal = (state: State) => {
    return state.calendar.calendar.showTicketModal;
};

export const selectCalendarInterval = (state: State) => {
    return state.calendar.calendar.interval;
};

export const selectSlotValidationResult = (state: State) => {
    return state.calendar.calendar.validation.result;
};

export const selectShowBasket = (state: State) => {
    return state.calendar.calendar.showBasket;
};

export const selectShowPlaceDetail = (state: State) => {
    return state.calendar.calendar.showPlaceDetail;
};


export const selectTickets = (state: State) => {
  return state.calendar.tickets.data;
};

export const selectTicketAvailableIntervals = (state: State) => {
  return state.calendar.tickets.intervals;
};

export const selectSeasonTickets = (state: State) => {
  return state.calendar.tickets.seasons;
};

