import React from 'react';
import Rating from './Rating';
import RouteLink from '../../app/components/RouteLink';
import useRoutes from '../../../routing/utils/useRoutes';
import {useConfiguration} from '../../../configuration/useConfiguration';
import {Sportground} from '../../catalog/models';

interface ComponentProps {
  data: Sportground;
}

const SportgroundComponent: React.FC<ComponentProps> = ({data}) => {
  const routes = useRoutes();
  const {cdnUrl} = useConfiguration();

  const imageStyle = {backgroundImage: `url(${cdnUrl + data.image})`};

  return <RouteLink to={routes.catalogSportground.url({sportgroundSlug: data.url.target})}>
    <div className="thumbnail" style={data.image ? imageStyle : {}}/>
    <div className={'distance' + (!data.distance ? ' transparent' : '')}>
      {' '}
      {data.distance && data.distance.toFixed(1) + 'KM'}
    </div>
    <div className="footer">
      <div className="displayFlex rating">
        <Rating average={Number(data.rating.toFixed(0))}/>
      </div>
      <h3>{data.name}</h3>
    </div>
  </RouteLink>;
};

export default SportgroundComponent;
