import dynamic from 'next/dynamic';
import {BaseLayoutProps} from "../layoutDefault";

type LayoutType = 'Default' | 'Empty';

export type Layout = React.ComponentType<BaseLayoutProps>;

const Layouts: Record<LayoutType, Layout> = {
  Default: dynamic(() => import('../layoutDefault')),
  Empty: ({ children }) => <>{children}</>,
};

export default Layouts;
