import styled from "styled-components";
import {sizes} from "../../../styles/mediaQueries";

export const SC = {
    Calendar: styled.div`


      .main-text {
        margin-left: auto;
        margin-right: auto;
        width: 75%;
        text-align: center;
        font-size: 16px;
        @media (max-width: ${sizes.sDevice}) {
          width: 100%;
        }
      }

      .read-more {
        font-size: 16px;
        font-weight: bolder;
        text-decoration: underline;
        margin: 2rem;
        cursor: pointer;
        user-select: none;
      }

      .calendar {
        margin-top: 2rem;

        .calendar-scroll {
          margin-top: 0;
          padding-right: 0;
          @media (max-width: ${sizes.sDevice}) {
            margin-top: 1rem;
            padding-right: 2rem;
            padding-left: 2rem;
          }


          .calendar-holder {
            padding-right: 3rem;
            padding-left: 3rem;
            @media (max-width: ${sizes.sDevice}) {
              padding-right: 0rem;
              padding-left: 0rem;
              overflow: auto;
            }
          }
        }

        .calendar-category-title {
          font-weight: 700;
          margin-top: 2rem;
          font-size: 2.1rem;
          margin-bottom: 2rem;
        }

        .calendar-next-button {
          opacity: 0;
          cursor: pointer;
        }

        .text-secondary {
          color: #6c757d;
        }

        .calendar-controls {
          padding: 1rem 0 !important;
          border-bottom: 1px solid #dbdbdb;
          justify-content: space-between !important;


          .hide {
            @media (max-width: ${sizes.sDevice}) {
              display: none;
            }
          }

          &-route {
            display: flex;
            flex-direction: row !important;


            .next-text, .prev-text {
              margin: 0rem 1rem;

              @media (max-width: ${sizes.sDevice}) {
                display: none;
                margin: 0;
              }
            }
          }

          @media (max-width: ${sizes.sDevice}) {
            display: none;
            margin-left: 3rem;
            margin-right: 3rem;
          }
        }


        .interval-bar {

          @media (max-width: ${sizes.sDevice}) {
            width: fit-content;
            padding-bottom: 1rem;

          }

          .timeline-header-name {
            min-width: 260px;

            @media (max-width: ${sizes.sDevice}) {
              display: none;
            }
          }

          .timeline-intervals {
            display: flex;
            padding-top: 3rem;
            padding-bottom: 2rem;

            &-main {
              @media (max-width: ${sizes.sDevice}) {
                display: none;
              }
            }

            &-mobile {
              @media (max-width: ${sizes.sDevice}) {
                padding-top: 1rem;
                display: flex;
              }
            }
          }

          .timeline-header {
            font-weight: bolder;
            box-sizing: border-box;
            border-right: 0;
            width: 100px;
            font-size: 12px;
          }

          .place-bar {
            display: flex;

            &-title {
              margin: auto;
              padding-top: 1rem;
              padding-bottom: 1rem;
              display: flex;

              &-name {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 1rem;
              }
            }

            @media (max-width: ${sizes.sDevice}) {
              display: none;
            }

            &-mobile {
              display: none;

              @media (max-width: ${sizes.sDevice}) {
                display: block;
              }
            }

            .name {
              font-size: 14px;
              font-weight: 800;
              min-width: 260px;
              height: 4.25rem;
              border-bottom: 0;
              border-right: 0;
              padding-left: 0.5rem;
              line-height: 2rem;

              @media (max-width: ${sizes.sDevice}) {
                font-size: 13px;
                height: 2.5rem;
                position: sticky;
                left: 0;
                width: 240px;
                margin-top: 1.8rem;
                img {
                  margin-left: 1rem;
                }
              }
            }


            .timeline-interval {
              border: 1px solid white;
              border-radius: 3px !important;
              box-sizing: border-box;
              height: 4.3rem;
              width: calc(var(--interval) * 120px);

              @media (max-width: ${sizes.sDevice}) {
                height: 4.5rem;
                width: calc(var(--interval) * 25px);
              }
            }
          }
        }
      }


      .legend {
        padding: 0 1.5rem;
        margin-bottom: 2rem;
        margin-left: 1.5rem;

        .calendar-legend {
          display: flex;
          margin-right: 3rem;
          margin-top: 4rem;
          margin-bottom: 2rem;
          @media (max-width: ${sizes.sDevice}) {
            display: block;
            margin-left: 3rem;
            margin-top: 1rem;
            margin-bottom: 2rem;

          }

          .calendar-legend-box {
            display: flex !important;
            margin-right: 2rem;

            @media (max-width: ${sizes.sDevice}) {
              margin-top: 1rem;
            }

            &-dot {
              width: 9px;
              height: 9px;
              border-radius: 50%;
              margin: auto;
              margin-right: 0.75rem;
              @media (max-width: ${sizes.sDevice}) {
                margin: initial;
                margin-right: 1rem;
              }
            }

            &-title {
              font-size: 14px;
              line-height: 19px;
              @media (max-width: ${sizes.sDevice}) {
                line-height: 10px;
              }
            }
          }

        }


        @media (max-width: ${sizes.sDevice}) {
          padding: 0;
          margin: 2rem 0;

          .name {
            display: none;
          }
        }
      }

      .min-height-260 {
        min-height: 260px;
      }

      .active-black {
        font-weight: bolder !important;
        border-color: #000;
        background-color: black;
        color: white !important;

        img {
          filter: invert(1);
        }
      }

      .unset-all {
        all: initial;

        p {
          all: unset;
        }
      }

      .mobile {
        display: none;
        @media (max-width: ${sizes.sDevice}) {
          display: block !important;
        }
      }

      .d-invisible {
        bottom: 0;
        position: absolute;
        padding: 0 !important;
        opacity: 0;
        display: block !important;
        width: 100%;
      }

      .date-control {
        position: relative;
        border: 1px solid #dedede;
        border-radius: 4px;
        //margin: 0 0.2rem;
        font-family: Nunito, serif;
        font-style: normal;
        font-weight: normal;
        background: #fff;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.015em;
        color: #141126;
        height: 48px;
        padding-left: 1.5rem;
        user-select: none;

        &::after {
          content: "";
          width: 14px;
          height: 9.5px;
          right: 1rem;
          margin: 0;
          position: absolute;
          background-image: url("/icons/Vector 1.svg");
          background-size: contain;
          background-repeat: no-repeat;
          /* transform: rotate(-90deg); */
          transition-property: transform;
          transition-timing-function: ease-out, ease-in, linear, ease-in-out;
          transition-duration: 0.5s;
          bottom: 1.1rem;
        }


        .value {
          margin: 0;
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          overflow: hidden;
          width: 75%;
          white-space: nowrap;
        }

        .dropdown {
          background-color: #fff;
          width: 101%;
          left: -1px;
          padding: 0.5rem 1rem;
          z-index: 100;
          position: absolute;
          top: 48px;
          border-radius: 0 0 5px 5px;
          border: 1px solid #dedede;
          display: none;

          &.show {
            display: block;
          }

          ul {
            padding: 0;

            li {
              list-style: none;
              text-decoration: underline;
            }
          }

          @media (max-width: ${sizes.sDevice}) {
            width: 100%;
            position: absolute;
            left: 0;
            margin-top: 0.6rem;
          }
        }
      }

      .dropdown-control {
        position: relative;
        border: 1px solid #dedede;
        border-radius: 4px;
        //margin: 0 0.2rem;
        font-family: Nunito, serif;
        font-style: normal;
        font-weight: normal;
        background: #fff;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.015em;
        color: #141126;
        height: 48px;
        padding-left: 1.5rem;
        user-select: none;

        &::after {
          content: "";
          width: 14px;
          height: 9.5px;
          margin: 0;
          position: absolute;
          background-image: url("/icons/Vector 1.svg");
          background-size: contain;
          background-repeat: no-repeat;
          /* transform: rotate(-90deg); */
          transition-property: transform;
          transition-timing-function: ease-out, ease-in, linear, ease-in-out;
          transition-duration: 0.5s;
        }

        .value {
          margin: 0;
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          overflow: hidden;
          width: 75%;
          white-space: nowrap;
        }

        .dropdown {
          background-color: #fff;
          width: 101%;
          left: -1px;
          padding: 0.5rem 1rem;
          z-index: 100;
          position: absolute;
          top: 48px;
          border-radius: 0 0 5px 5px;
          border: 1px solid #dedede;
          display: none;

          &.show {
            display: block;
          }

          ul {
            padding: 0;

            li {
              list-style: none;
              text-decoration: underline;
            }
          }

          @media (max-width: ${sizes.sDevice}) {
            width: 100%;
            position: absolute;
            left: 0;
            margin-top: 0.6rem;
          }
        }
      }

      .rules {
        word-wrap: break-word;

        h1 {
          font-size: xx-large !important;
        }

        p, span {

          font-family: Nunito, serif !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 16px !important;
          line-height: 145% !important;
          color: #000000 !important;
          margin-bottom: 0 !important;
          height: fit-content;
          padding: 4px !important;
          margin-left: 0px !important;
        }

        strong {
          font-family: Nunito, serif !important;
          font-style: normal !important;
          font-weight: bold !important;
          font-size: 18px !important;
          line-height: 145% !important;
          color: #000000 !important;
        }

        ul, ol {
          padding-inline-start: 40px !important;

          li {
            list-style: none !important;
            margin-left: 0 !important;
            display: flex;
            margin-bottom: 16px;

            &:first-child {
              margin-top: 39px
            }

            &:last-child {
              margin-bottom: 16px;
            }

            &:before {
              background-image: url("/icons/listItem.svg");
              display: block;
              content: "";
              border-bottom: 0;
              padding-left: 10px;
              background-repeat: no-repeat;
              position: relative;
              top: 7px;
              left: -10px;
            }
          }

          span, p {
            font-family: Nunito, serif !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 18px !important;
            line-height: 26px !important;
            color: #000000 !important;
            //padding-bottom: 1em;
            height: fit-content;
          }
        }
      }

      .d-flex {
        display: flex;
      }

      .flex-direction-column {
        flex-direction: column;
      }

      .justify-content-center {
        justify-content: center;
      }

      .gap-2 {
        gap: 1rem;
      }

      .d-none {
        display: none
      }

      .opacity-0 {
        opacity: 0;
      }

      .position-absolute {
        position: absolute;
      }

      .position-relative {
        position: relative;
      }

      .top-0 {
        top: 0;
      }

      .start-0 {
        left: 0;
      }

      .end-0 {
        right: 0;
      }

      .bottom-0 {
        bottom: 0;
      }



      .banner-calendar {
        display: block;
        height: 13rem;
        width: 100%;
        background-image: linear-gradient(to right, #6b96f5, #7144f4);
        position: relative;
        margin-bottom: 1rem;

        .banner-logo-img-wrapper {
          display: flex;
          justify-content: center;
          text-align: center;
          margin-bottom: 3rem;
          align-items: center;

        }

        .banner-img-wrapper {
          position: absolute;
          margin: auto 0;
          top: 39%;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
        }

        .banner-img {
          display: flex;
          width: 135px;
          height: 135px;
          background-color: white;
          border: 1px solid #dbdbdb;
          border-radius: 100px;
          padding: 2rem;
          -webkit-box-shadow: 4px 2px 5px 0px rgba(0, 0, 0, 0.09);
          -moz-box-shadow: 4px 2px 5px 0px rgba(0, 0, 0, 0.09);
          box-shadow: 4px 2px 5px 0px rgba(0, 0, 0, 0.09);

          img {
            display: block;
            margin: auto;
          }
        }
      }

      .calendar-wrapper {
        border: 1px solid #dbdbdb;
        border-radius: 5px;
        margin-bottom: 7rem;

          //@media (max-width: ${sizes.sDevice})
      {
        //  border: none;
        //  margin-bottom: 3rem;
        //}

      }

        .calendar-alert-box {

          @media (max-width: ${sizes.sDevice}) {
            width: 100vw;
          }

        }

        .calendar-next-button {
          font-size: 8px !important;
          height: 33px !important;
          @media (max-width: ${sizes.sDevice}) {
            width: 0px;
          }
        }

        .calendar-controls {
          display: flex;
          width: 100%;
          justify-content: space-around;
          border-bottom: 1px solid #dbdbdb;

          @media (max-width: ${sizes.sDevice}) {
            width: initial;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            padding-top: 1.5rem !important;
            padding-bottom: 1.5rem !important;

          }

        }

        a {
          font-size: 14px;
          flex-direction: column;
          padding: 2px 13px;
          margin: 5px 7px;
          border-radius: 5px;
          font-weight: 800;
          line-height: 27px;
          @media (max-width: ${sizes.sDevice}) {
            padding: 1px 8px;
          }

        }
      }
    }

    .container-calendar {
      margin-top: 11rem;
    }

    .my-auto {
      margin-top: auto;
      margin-bottom: auto;
    }


    .slick-slider {

      .slick-slide {
        width: fit-content !important;
        :first-child {
            width: fit-content !important;
        }
      }

      .slick-arrow {
        display: none !important;
      }

      .slick-cloned {
        display: none !important;
      }


    }

    `,
};
