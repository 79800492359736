import React from 'react';
import {NextComponentProps, NextPageComponent} from '../types';
import {SC} from "../modules/homepage/homepage.styled";
import Layouts from "../modules/ui/components/layout/type";
import FavoriteCategoryBar from "../modules/homepage/components/favoriteCategoryBar";
import AroundSportGroundBar from "../modules/homepage/components/aroundSportGroundBar";
import {loadAroundSportgrounds, loadCategoryForHomepage, loadTopCities} from "../modules/homepage/actions";
import {useRouting} from "../routing/utils/useRouting";
import {isServer} from "../utils";
import {prefetchQueries} from "../common/utils/reactQuery";
import {
  getSportground,
  getSportgroundQueryKey,
  useSportgroundQuery
} from "../modules/catalog/queries/useSportgroundQuery";
import {resolveSeoUrl} from "../modules/app/actions";
import {SeoResourceType} from "../modules/app/models/enums";
import {ResolveSeoUrlActionFinish} from "../modules/app/actions/actionTypes";
import {HttpStatus} from "../types/enums/network";
import {useMicro} from "../utils/hooks/useMicro";
import CalendarPageWrapper from "../modules/calendar/components/calendarPageWrapper";
import {SC as SCalendaer} from "../modules/calendar/styles/calendarPageWrapper.styled";
import {queryParamToString} from "../storage/query";
import {getCalendarOpenHours} from "../modules/calendar/actions";
import {initDate} from "../helpers/dateHelper";

interface ComponentProps extends NextComponentProps {
  resourceId?: string | null
  category?: { id: string, url: string } | null
}

const HomePage: NextPageComponent<ComponentProps> = ({category, resourceId: serverSideResourceId}) => {
  const {routes, router} = useRouting();
  const {resourceId} = useMicro();
  const {data, isLoading} = useSportgroundQuery(resourceId);

  if (resourceId || serverSideResourceId) {
    return <SC.Homepage>
      <SCalendaer.Calendar>
        <CalendarPageWrapper resourceId={resourceId || serverSideResourceId} category={category}/>
      </SCalendaer.Calendar>
    </SC.Homepage>
  }

  return <SC.Homepage>
    <SCalendaer.Calendar/>
    <div className="container" key={'container-key-index'}>
      <FavoriteCategoryBar/>
      <AroundSportGroundBar/>
    </div>
  </SC.Homepage>;
};

HomePage.getInitialProps = async (ctx) => {
  const {
    req, res,
    query,
    store: {dispatch},
  } = ctx;

  const splitted: string[] | undefined = req?.headers.host?.split('.');
  const sportgroundDomain = splitted?.length == 3 ? splitted[0] : null;

  if (!sportgroundDomain) {
    await dispatch(loadAroundSportgrounds({}, 3, 1));
    await dispatch(loadCategoryForHomepage({findFavorite: true}, 10, 1));
    await dispatch(loadTopCities(3, 1));

    return {};
  }

  // prefetch query for sportground
  const {result} = await dispatch(resolveSeoUrl({
    value: sportgroundDomain,
    resourceType: SeoResourceType.MICRO,
  })) as ResolveSeoUrlActionFinish;

  if (result == null) {
    res?.writeHead(HttpStatus.TemporaryRedirect, {Location: '/error/404'}).end();
  }

  if (result?.redirectCode != null) {
    // TODO implement redirect
    // res?.writeHead(result?.redirectCode, {Location: `/${translateSeoUrlType(result.redirectType as SeoResourceType)}/${result.redirectPath}`}).end()
  }
  if (result?.resourceId) {
    if (isServer()) {
      const [categorySlug, calendarDate] = queryParamToString(query.categorySlug);

      const {result: category} = await dispatch(resolveSeoUrl({
        value: categorySlug as string,
        resourceType: SeoResourceType.SPORTGROUND_CATEGORY,
        sportgroundId: result?.resourceId,
      })) as ResolveSeoUrlActionFinish;

      await dispatch(getCalendarOpenHours(result.resourceId,
        {
          date: calendarDate ? calendarDate : initDate().toISOString(),
          categoryId: category ? category.resourceId : null,
        }))

      const queryClient = await prefetchQueries([[
        getSportgroundQueryKey(result?.resourceId), () => getSportground(result?.resourceId),
      ]]);

      return {
        resourceId: result?.resourceId,
        dehydratedState: queryClient,
        category: category ? {
          id: category.resourceId,
          url: categorySlug as string,
        } : null,
      };
    }
  }

  return {};

};

HomePage.settings = {
  pageType: 'index',
  layout: Layouts.Default,
  layoutProps: {
    isIndex: true
  }
};

export default HomePage;
