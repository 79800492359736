import React from 'react';
import {Image} from "react-bootstrap";
import {useConfiguration} from "../../../../configuration/useConfiguration";
import {SportgroundCategory} from "../../../catalog/models";

interface ComponentProps {
  row: SportgroundCategory
  category?: string
  onClick: () => void,
  className?: string | undefined
}

const CalendarCategoryBlock: React.FC<ComponentProps> = ({
                                                           row,
                                                           category,
                                                           onClick,
                                                           className = "category-row d-flex cursor-pointer me-3 w-200px"
                                                         }) => {
  const {cdnUrl} = useConfiguration();

  return <div className={`${className} ${category == row.url.target && "active-black"}`}
              onClick={onClick}>
    {row.image != null && <Image key={row.id}
                                 src={cdnUrl + row.image}
                                 className={'img-fluid my-auto m-3'}
                                 alt={row.name}
                                 loading={'lazy'}
    />}
    {(row.image == null && row.id != null) && <div className={"m-3"}/>}
    <div className={'my-auto'} style={{fontWeight: 800}}>{row.name}</div>
  </div>
}

export default CalendarCategoryBlock;
