import {useQuery} from 'react-query';
import ApiClient from '../../../lib/api/src/api';
import {returnOrThrowApiResponse} from "../../../lib/api/utils";
import {SportgroundDetailDataMicro} from "../models";
import {GenericResponse} from "../../common/models";

export const getSportgroundQueryKey = (id: string): string => `sportground_${id}`;

export const getSportground = async (sportgroundId: any): Promise<SportgroundDetailDataMicro> => {
  const resp = await new ApiClient()
    .get<GenericResponse<SportgroundDetailDataMicro>>('GET_SPORTGROUND_DETAIL_DATA',
      {
        urlParts: [sportgroundId]
      }
    );
  return returnOrThrowApiResponse(resp).result;
};

export const useSportgroundQuery = (sportgroundId: string) => {

  return useQuery(getSportgroundQueryKey(sportgroundId ?? ''), ({queryKey}) => getSportground(sportgroundId), {
    enabled: !!sportgroundId,
  });
};

