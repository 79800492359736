export enum ServiceReservationType {
  SERVICE_RESERVATION = 'SERVICE_RESERVATION',
  BLOCKING = 'BLOCKING'
}

export enum ServiceReservationResourceType {
  RESERVATION = 'RESERVATION',
  SERVICE_RESERVATION = 'SERVICE_RESERVATION'
}

export enum ReservationStatusEnum {
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export enum CalendarOpenValue {
  OPEN = 'OPEN',
  SPORTGROUND_CLOSED = 'SPORTGROUND_CLOSED',
  PLACE_CLOSED = 'PLACE_CLOSED',
  RESERVATION = 'RESERVATION',
  SERVICE_RESERVATION = 'SERVICE_RESERVATION',
  RESERVATION_LONG_TERM = 'RESERVATION_LONG_TERM',
  TIME_CLOSED = 'TIME_CLOSED'
}

export enum PaymentMethodType {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT = 'CREDIT'
}

export enum ColumnBlockType {
  RESERVATION = 'reservation',
  AVAILABLE = 'free',
  CLOSED = 'closed',
  MAINTENANCE = 'service-reservation'
}

export enum SportgroundCategoryType {
  RESERVATION = 'RESERVATION',
  TICKET = 'TICKET'
}



export enum CreateReservationStep {
  INIT = 'INIT',
  ABOUT = 'ABOUT'
}

export enum ResourceType {
  LOGO = 'LOGO'
}

export enum SportgroundSettings {
  SPORTGROUND_REGISTER_PHONE = 'SPORTGROUND_REGISTER_PHONE',
  SPORTGROUND_REGISTER_BIRTHDATE = 'SPORTGROUND_REGISTER_BIRTHDATE',
}