import React, {useEffect} from 'react';
import useTranslations from '../../../utils/hooks/useTranslations';
import {useRouting} from '../../../routing/utils/useRouting';
import {useDispatch, useSelector} from 'react-redux';
import RouteLink from '../../app/components/RouteLink';
import SportgroundComponent from './sportgroundComponent';
import useGeolocation from '../../../utils/hooks/useGeolocation';
import {selectAroundSportgrounds} from '../reducers/selectors';
import {loadAroundSportgrounds} from '../actions';
import {SC} from "./arroundSportground.styled";

const AroundSportGroundBar: React.FC = () => {
    const {ts} = useTranslations();
    const {routes} = useRouting();

    const [geolocation, getLocation] = useGeolocation();
    // eslint-disable-next-line
    // let [refreshKey, setRefreshKey] = useState<number>(0);

    const dispatch = useDispatch();
    const sportgrounds = useSelector(selectAroundSportgrounds);

    // todo save to state after first load
    useEffect(() => {
        // first load
        dispatch(loadAroundSportgrounds({}, 3, 1));
        getLocation();
    }, []);

    useEffect(() => {
        if (geolocation) {
            dispatch(
                loadAroundSportgrounds({coordinates: {x: geolocation?.lng, y: geolocation?.lng}}, 3, 1)
            );

            // setRefreshKey(refreshKey++);
        }
    }, [geolocation]);

    // useEffect(() => {
    //     if (geolocation && sportgrounds.length === 0 && refreshKey < 1) {
    //         dispatch(
    //             loadAroundSportgrounds({}, 3, 1)
    //         );
    //         setRefreshKey(refreshKey++);
    //     }
    // }, [sportgrounds, geolocation, refreshKey]);

    if (!sportgrounds || sportgrounds.length === 0) {

        return <></>;
    }

    return  <SC.ArroundSportground>
      <div className="flexJustifyBetween">
        <h2>Sportoviště v okolí</h2>
        <RouteLink to={routes.catalogSportgrounds.url()} className={"link"}>
          Zobrazit vše
        </RouteLink>
      </div>
      <div className="sportgroundArroundList">
        <div className="displayFlex mo-flexColumn">
          {sportgrounds.map((sportground) => {
            return <SportgroundComponent key={sportground.id} data={sportground}/>;
          })}
        </div>
      </div>
    </SC.ArroundSportground>;
};


export default AroundSportGroundBar;
