import React, {useRef, useState} from 'react';
import {useOutsideAlerter} from "../../../utils/hooks/useOutsideAlerter";

export interface ComponentProps {
  label: string,
  disabled?: boolean
}

const DropdownControl: React.FC<ComponentProps> = ({children, label, disabled = false}) => {

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  // TODO HACK, pls better solution
  const ref = useRef(null);
  const {outsideAlerter} = useOutsideAlerter();

  outsideAlerter(ref, setShowDropdown);

  const onClick = () => {
    if (disabled) {
      return
    }

    setShowDropdown(!showDropdown);
  };

  return <div className={`dropdown-control ${showDropdown ? 'show' : ''} ${disabled ? 'disabled' : ''}`}
              ref={ref}
              onClick={onClick}>
    <div className={"value"}>{label}</div>
    <div className={'dropdown' + (showDropdown ? ' show' : '')}>
      {children}
    </div>
  </div>;
};

export default DropdownControl;
