import { Notification } from 'react-notification-system';
import Notifications, { NotificationLevel } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { DEFAULT_NOTIFICATION_DISMISS } from 'src/common/constants';
import { Message, MessageType } from '../../modules/common/models';

export const DEFAULT_MESSAGE: Notification = {
  message: '',
  position: 'tc',
  autoDismiss: DEFAULT_NOTIFICATION_DISMISS,
};

const useNotification = () => {
  const dispatch = useDispatch();

  const notify = useCallback(
    (message: Translation | Notification | Message, level: NotificationLevel | MessageType = 'info') => {
      let m: Notification;
      if (typeof message === 'string') {
        m = { ...DEFAULT_MESSAGE, message, uid: message as any };
      } else {
        m = message as Notification;
      }
      dispatch(Notifications.show(m, level));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      notify,
    }),
    [notify]
  );
};

export default useNotification;
