import {Action, ActionFunction} from '../../../types';
import {
    SearchFormData,
} from '../models';
import createReduxAction from '../../common/actionCreator';
import {CategoryFinderPayload, SportgroundFinderPayload} from '../../catalog/models';

export function loadCategoryForHomepage(
    payload: CategoryFinderPayload,
    limit: number,
    page: number
): ActionFunction<Action> {
    return createReduxAction({
        type: 'LOAD_CATEGORY_FOR_HOMEPAGE_ACTION',
        promise: ({api}) =>
            api.post('CATEGORY_CACHE_FINDER', {
                data: payload,
                urlParams: {size: limit, page},
            }),
    });
}

export function loadAroundSportgrounds(
    payload: SportgroundFinderPayload,
    limit: number,
    page: number
): ActionFunction<Action> {
    return createReduxAction({
        type: 'GET_AROUND_SPORTGROUND_ACTION',
        promise: ({api}) =>
            api.post('SPORTGROUND_CACHE_FINDER', {
                data: payload,
                urlParams: {size: limit, page},
            }),
    });
}

export function loadTopCities(limit: number, page: number): ActionFunction<Action> {
    return createReduxAction({
        type: 'GET_TOP_CITIES_ACTION',
        promise: ({api}) => api.get('AGGREGATE_SPORTGROUND_CITY', {urlParams: {size: limit, page}}),
    });
}


export function setSearchFormData(data: SearchFormData): Action {

    return {
        type: 'SET_SEARCH_FORM_DATA',
        data
    };
}