import Loader from "../../ui/components/loader";
import {useDispatch, useSelector} from "react-redux";
import {selectShowPlaceDetail} from "../reducers/selectors";
import {useState} from "react";
import {useConfiguration} from "../../../configuration/useConfiguration";
import {ResourceType} from "../models/enums";
import {SporgroundContentType} from "../../catalog/models";
import {Image} from "react-bootstrap"
import CalendarWrapper from "./calendarWrapper";
import {useSportgroundQuery} from "../../catalog/queries/useSportgroundQuery";
import DynamicModals from '../../ui/dynamicModals';
import {setShowPlaceDetail} from "../actions";


interface ComponentProps {
  resourceId: string
  category?: {
    id: string,
    url: string,
  } | null,
}

const CalendarPageWrapper: React.FC<ComponentProps> = ({resourceId, category}) => {

  const {data} = useSportgroundQuery(resourceId);
  const {cdnUrl} = useConfiguration();
  const [show, setShow] = useState<boolean>(false)
  const showPlaceDetail = useSelector(selectShowPlaceDetail);
  const dispatch = useDispatch();

  if (!data) {
    return <Loader/>
  }

  return <>
    <div className={'banner-calendar'} key={'container-key-calendar'}>
      {data.resources && data.resources[ResourceType.LOGO] && <div
        className={'banner-logo-img-wrapper'}>
        <div className={'banner-img-wrapper'}>
          <div className={'banner-img'}>
            <Image
              src={`${cdnUrl}${data.resources[ResourceType.LOGO] }`}
              className={'img-fluid'}
              width={"200px"}
              alt={data?.name}
              loading={'lazy'}
            />
          </div>
        </div>
      </div>}
    </div>
    <div className={'container'} style={{marginTop:'11rem'}}>
      <div className={'row justify-content-center'}>
        <div className={'col-md-9 align-items-center mt-md-4 d-flex col-12 text-center main-text'}>
          {data.contents.map(content => {
            if (content.type == SporgroundContentType.MICRO_SITE_ABOUT) {
              return <>
                <div
                  dangerouslySetInnerHTML={{__html: `${content.value.substring(0, show ? content.value.length - 1 : 255)}${!show && content.value.length > 255 && "..."}`}}/>
              </>
            }
            return undefined;
          })}
        </div>
        {data.contents.map(content => {
          if (content.type == SporgroundContentType.MICRO_SITE_ABOUT) {
            if (content.value.length > 255) {
              return <div className={'col-md-12 text-center read-more'}>
                <div className={""}
                     onClick={() => setShow(!show)}>{show ? "Číst méně" : "Číst více"}</div>
              </div>
            }
          }
          return undefined;
        })}
      </div>
      <CalendarWrapper resourceId={resourceId} category={category}/>
    </div>
    {showPlaceDetail.show && (
      <DynamicModals.PlaceDetailModal
        open={showPlaceDetail.show}
        hideModal={() => {
          dispatch(setShowPlaceDetail({show: false}));
        }}
      />
    )}
  </>
}

export default CalendarPageWrapper;
