import React, {useEffect, useState} from 'react';
import RouteLink from '../../app/components/RouteLink';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Image} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

import useTranslations from '../../../utils/hooks/useTranslations';
import {useRouting} from '../../../routing/utils/useRouting';
import {useConfiguration} from '../../../configuration/useConfiguration';
import {selectHomepageCategory} from '../reducers/selectors';
import {loadCategoryForHomepage} from '../actions';
import {v4 as uuidv4} from 'uuid';
import {SC} from "./favoriteCategoryBar.styled";
import SwiperCore, {Autoplay} from "swiper";

SwiperCore.use([Autoplay]);

const autoplayConfig = {
  delay: 2500,
  disableOnInteraction: false,
  pauseOnMouseEnter: true,
};

const breakpoints = {
  '0': {
    slidesPerView:3,
    spaceBetween: 4,
    allowTouchMove: true,
  },
  '425': {
    slidesPerView: 3,
    spaceBetween: 4,
    allowTouchMove: true,
  },
  '768': {
    slidesPerView: 3,
    spaceBetween: 16,
    allowTouchMove: true,
  },
  '1400': {
    slidesPerView: 6,
    spaceBetween: 24,
    allowTouchMove: true,
  },
};

const FavoriteCategoryBar: React.FC = () => {
  const {ts} = useTranslations();
  const {routes} = useRouting();
  const {cdnUrl} = useConfiguration();

  const [refreshKey] = useState<number>(0);

  const dispatch = useDispatch();
  const categories = useSelector(selectHomepageCategory);

  useEffect(() => {
    dispatch(loadCategoryForHomepage({findFavorite: true}, 10, 1));
  }, [refreshKey]);

  if (!categories || categories.length === 0) {
    return <></>;
  }

  return <SC.FavoriteCategoryBar>
    <div className="flexJustifyBetween">
      <h2>{ts('homepage:favoriteCategoriesTitle')}</h2>
      <RouteLink to={routes.catalogCategories.url()} className={'link'}>
        {ts('homepage:viewAllCategories')}
      </RouteLink>
    </div>
    <Swiper
      slidesPerView={'auto'}
      spaceBetween={24}
      loop={false}
      direction={'horizontal'}
      autoplay={autoplayConfig}
      allowTouchMove={false}
      breakpoints={breakpoints}
      className={'favoriteList'}
    >
      <div className="swiper-wrapper">
        {categories.map((category) => {
          return <SwiperSlide className="sports text-center" key={uuidv4()}>
            <RouteLink
              to={!category.url?.target ? null : routes.catalogCategory.url({categorySlug: category.url.target})}>
              <div className={"displayFlex blockAlignItemsCenter"}>
                {category.image && (
                  <Image key={category.id}
                         src={cdnUrl + category.image}
                         className={'img-fluid'}
                         alt={'Ikona sportu'}
                         loading={'lazy'}
                  />
                )}
                <p>{category.name}</p>
              </div>
            </RouteLink>
          </SwiperSlide>;
        })}
      </div>
    </Swiper>
  </SC.FavoriteCategoryBar>;
};

export default FavoriteCategoryBar;
