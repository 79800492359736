import styled from "styled-components";
import {sizes} from "../../../styles/mediaQueries";

export const SC = {
  ArroundSportground: styled.div`
    //margin-top: 7em;
    margin-bottom: 57px;

    h2 {
      font-family: HelveticaNeue, serif;
      font-style: normal;
      font-weight: 300;
      font-size: 26px;
      line-height: 31px;
      padding-bottom: 2rem;
      color: #141126;

      @media (max-width: ${sizes.mDevice}) {
        font-size: 18px;
        font-weight: 100;
        padding-bottom: 0;
      }
    }

    .link {
      text-decoration: none;
      font-family: Nunito, serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      color: #141126;

      @media (max-width: ${sizes.mDevice}) {
        margin-bottom: 12px;
      }

    }

    .sportgroundArroundList {
      a {
        margin-left: 10px;
        text-decoration: none;

        :first-child {
          margin-left: initial;
        }

        h3 {
          font-family: Nunito, serif;
          font-weight: 600;
          font-size: 25px;
          line-height: 40px;
          color: #141126;
          @media (max-width: ${sizes.mDevice}) {
            font-size: 14px;
            line-height: 20px;

          }
        }

        .thumbnail {
          width: 424px;
          height: 280px;
          display: block;
          background-size: cover;
          border-radius: 8px;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("/images/sportground1.jpg");
          @media (max-width: ${sizes.mDevice}) {
            width: 100%;
            height: 200px;
          }
        }

        .distance {
          background: #F72E50;
          font-family: Nunito, serif;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 20px;
          color: #FFFFFF;
          position: relative;
          bottom: 3rem;
          left: 1.5rem;
          width: fit-content;
          text-align: center;
          padding: 0 2rem;
          min-height: 2rem;

          &.transparent {
            background: transparent !important;
          }
        }

        .footer {
          margin-left: 1rem;
          @media (max-width: ${sizes.mDevice}) {
            margin-top: -1rem;
          }

          .rating {
            gap: 5px;

            img {
              width: 15px;
              height: 15px;
              @media (max-width: ${sizes.mDevice}) {
                width: 10px;
                height: 10px;
              }
            }
          }
        }
      }
    }
  `,
};
