import Slider, {Settings} from "react-slick";
import CalendarCategoryBlock from "./calendarCategoryBlock";
import {EmptyCategory} from "../../models";
import {useSportgroundQuery} from "../../../catalog/queries/useSportgroundQuery";
import React from "react";
import DropdownControl from "../../../common/components/dropdownControl";
import {useRouting} from "../../../../routing/utils/useRouting";
import {SC} from "../../styles/categoryOptionWrapper.styled";


const settings: Settings = {
  responsive: [
    {
      breakpoint: 5000,
      settings: {
        dots: false,
        infinite: false,
        draggable: true,
        slide: 'button',
        arrows: false,
        variableWidth: true,
      },
    },
  ],
};

interface ComponentProps {
  resourceId: string
  category?: {
    id: string,
    url: string,
  } | null | undefined,
  date: any,
  calendarDate: any
}

const CategoryOptionWrapper: React.FC<ComponentProps> = ({resourceId, category, date, calendarDate}) => {
  const {router, routes} = useRouting()
  const {data: sportground} = useSportgroundQuery(resourceId);
  const {categorySlug} = router.query

  const getCategory = (val: string | null) => {
    if (sportground == null || val == null) {
      return null;
    }

    for (const category of sportground.categories) {
      if (category.url.target == val) {
        return category;
      }
    }
    return null;
  }
  const c = getCategory(categorySlug as string);

  const selectCategory = (val: string | null) => {
    const pushDate = new Date(calendarDate ? calendarDate as string : date);

    if (val == null) {
      router.replace(routes.index.url({
        calendarDate: pushDate.toISOString(),
      }),undefined,{scroll:false})
      return;
    }
    router.replace(routes.indexCategory.url({
      categorySlug: val,
      calendarDate: pushDate.toISOString(),
    }),undefined,{scroll:false})
  }


  return <SC.CategoryOptionWrapper>
    <div className={'calendar-category-title'}>Výběr sportů/aktivit</div>
    <div className={'mobile mb-3'}>
      <DropdownControl label={c ? c.name : 'Vše'}>
        <ul>
          <li key={0} onClick={() => selectCategory(null)}>Vše</li>
          {sportground?.categories.map(row => {
            return <li key={row.id} onClick={() => selectCategory(row.url?.target as string | null)}>
              {row.name}
            </li>
          })}
        </ul>
      </DropdownControl>
    </div>
    <div className={'category-swiper-wrapper'}>
      <Slider {...settings} >
        <CalendarCategoryBlock row={EmptyCategory}
                               onClick={() => selectCategory(null)}
                               category={categorySlug ? undefined : EmptyCategory.url.target}
                               className={"category-row first-category-block"}
        />
        {sportground?.categories.filter(category=>!!category.url).map(row => {
          return <CalendarCategoryBlock row={row}
                                        category={categorySlug as string}
                                        onClick={() => selectCategory(row.url?.target as string | null)}/>
        })}
      </Slider>
    </div>
  </SC.CategoryOptionWrapper>
}

export default CategoryOptionWrapper;
