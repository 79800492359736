import { useState } from 'react';
import useNotification from './useNotification';
import useTranslations from './useTranslations';
import { Location } from '../../modules/common/models';

const useGeolocation = (): [Location | null, () => void] => {
  const { ts } = useTranslations();
  const [location, setLocation] = useState<Location | null>(null);
  const { notify } = useNotification();

  const showLocation = (p: GeolocationPosition) => {
    const { latitude, longitude } = p.coords;
    setLocation({ lat: latitude, lng: longitude });

    return location;
  };

  const errorHandler = (err: GeolocationPositionError) => {

    // if (err.code === 1) {
    //   notify(ts('geolocation.notAllowed'));
    // } else if (err.code === 2) {
    //   notify(ts('geolocation.notAvailable'));
    // }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((p) => showLocation(p), errorHandler);
    } else {
      notify(ts('geolocation.notSupported'));
    }
  };

  return [location, getLocation];
};

export default useGeolocation;
