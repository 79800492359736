import {useDispatch, useSelector} from "react-redux";
import {setRefreshList} from "../../modules/app/actions";
import {getRefresh} from "../../helpers/commonHelper";
import {selectRefreshListValue} from "../../modules/app/reducer/selectors";

export const useRefreshListHook = () => {

    const effectValue = useSelector(selectRefreshListValue)
    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(setRefreshList(getRefresh()))
    }

    return {
        effectValue,
        refresh,
    };
}