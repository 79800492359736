import React from "react";
import styled from "styled-components";

const Loader: React.FC = () => {
    return <SC.Loader className={"loader-bar"} key={'loader-style'}>
        <div className={'loading-indicator'} key={'loader'}/>
        <div className={'text'}> Načítání...</div>
    </SC.Loader>;
}

export default Loader;

export const SC = {
  Loader: styled.div`

    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .loading-indicator {
      border: 3px solid #f3f3f3;
      border-radius: 50%;
      border-top: 3px solid #79D231;
      width: 50px;
      height: 50px;
      animation: spin 2s linear infinite;
      -webkit-animation: spin 2s linear infinite;
      margin: 0 auto;
    }

    .text {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.015em;
      color: #141126;
      font-family: Nunito, serif;
      font-style: normal;
      font-weight: normal;
      padding-top: 2rem;
    }
  `,
};
