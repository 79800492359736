export const DEFAULT_NOTIFICATION_DISMISS = 4;

export const ICO_REGEX = /(^$)|(^\d{8}$)/;
export const DIC_REGEX = /(^$)|(^CZ\d{8,10}$)/i
export const PHONE_REGEX = /(^$)|(^(\+?420|\+?421|\+?49)[1-9][0-9]{8}$)/
export const DECIMAL_2_REGEX = /^\d*(\.\d{0,2})?$/
export const POSITIVE_NUMBER = /^[1-9]*$/
export const STRING_LENGTH_REGEX = /^(?=.{0,255}$)/
export const MAX_VALUE = 99999;
export const TIME_INPUT_REGEX = /^[0-2][0-9]:[0-5][0-9]$/
export const IMAGE_SUFFIX = /\.(?:jpg|png)$/i
export const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
