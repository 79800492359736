import {useQuery} from 'react-query';
import {useRouting} from '../../../routing/utils/useRouting';
import {SportgroundDetailData} from '../models';
import ApiClient from '../../../lib/api/src/api';
import {GenericResponse} from '../../common/models';
import {returnOrThrowApiResponse} from "../../../lib/api/utils";

export const getSportgroundQueryKey = (id: string): string => `sportground_${id}`;

export const getSportground = async (sportgroundId: any): Promise<SportgroundDetailData > => {
    const resp = await new ApiClient()
        .get<GenericResponse<SportgroundDetailData>>('GET_SPORTGROUND_DETAIL',
            {
                urlParts: [sportgroundId]
            }
        );
    return returnOrThrowApiResponse(resp).result;
};

export const useSportgroundQuery = (sportgroundId: string | undefined | null) => {

    return useQuery(getSportgroundQueryKey(sportgroundId ?? ''), ({queryKey}) => getSportground(sportgroundId), {
        enabled: !!sportgroundId,
    });
};

