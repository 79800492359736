import React, {useEffect, useState} from 'react';
import {CalendarOpenValue} from "../models/enums";
import {useRouting} from "../../../routing/utils/useRouting";
import {useDispatch, useSelector} from "react-redux";
import {useDate} from "../../homepage/hooks/useDate";
import {useSportgroundQuery} from "../queries/useSportgroundQuerySeo";
import {CalendarBlock} from "../models";
import {
  selectCalendarInterval,
  selectCalendarOpenHours,
  selectShowBasket,
  selectShowReservationNew,
  selectShowTicketModal
} from "../reducers/selectors";
import {dateAtStart, getCalendarBackground, getCalendarHoverInformation} from "../helper";
import {
  getCalendarOpenHours,
  resetTicketAvailableIntervals,
  resetValidateSlotAvailability,
  setShowBasket,
  setShowReservationNew,
  setShowTicketModal
} from "../actions";
import RouteLink from "../../app/components/RouteLink";
import {Image} from 'react-bootstrap'
import {formatDate} from "../../../helpers/dateHelper";
import CalendarPicker from "../../common/components/calendarPicker/calendarPicker";
import Loader from "../../ui/components/loader";
import CalendarHolder from "./calendarHolder";
import CategoryOptionWrapper from "./categories/categoryOptionWrapper";
import DynamicModals from '../../ui/dynamicModals';
import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import {useRefreshListHook} from "../../../common/hooks/useRefreshListHook";


const LEGEND = [
  CalendarOpenValue.SPORTGROUND_CLOSED,
  CalendarOpenValue.TIME_CLOSED,
  CalendarOpenValue.OPEN,
  CalendarOpenValue.RESERVATION,
]

interface ComponentProps {
  resourceId: string
  category?: {
    id: string,
    url: string,
  } | null,
}

const CalendarWrapper: React.FC<ComponentProps> = ({resourceId, category}) => {
  const {router, routes} = useRouting();
  const dispatch = useDispatch();
  const {calendarDate, categorySlug} = router.query;
  const {date, setDate} = useDate(calendarDate ? new Date(calendarDate as string) : undefined);

  const {data: sportground} = useSportgroundQuery(resourceId);
  const sportgroundCategory = sportground?.categories.filter(category=>!!category.url).find(category => category.url.target == categorySlug);

  const [loading, setLoading] = useState(false)
  const [init, setInit] = useState(true)


  const data: CalendarBlock[] | undefined = useSelector(selectCalendarOpenHours);
  const showCreateModal = useSelector(selectShowReservationNew);
  const showTicketModal = useSelector(selectShowTicketModal);
  const showBasket = useSelector(selectShowBasket);

  const {isMobile} = useWindowDimensions()
  const intervalData = useSelector(selectCalendarInterval);
  const {refresh, effectValue} = useRefreshListHook();


  const generateUrl = (val: number, fromDate: boolean = false) => {

    const newDate = generateDate(val, fromDate);
    if (newDate < dateAtStart()) {
      return
    }

    if (categorySlug) {
      router.replace(routes.indexCategory.url({
        categorySlug,
        calendarDate: newDate.toISOString(),
      }), undefined, {scroll: false})
      return
    }

    router.replace(routes.index.url({
      calendarDate: newDate.toISOString(),
    }), undefined, {scroll: false})
  }

  const generateDate = (val: number, fromDate: boolean = false) => {
    const newDate = fromDate && calendarDate ? new Date(calendarDate as string) : new Date();
    newDate.setDate(newDate.getDate() + val);

    return newDate;
  }

  const generateDateFromMonday = (val: number) => {
    const newDate = calendarDate ? new Date(calendarDate as string) : new Date();
    const day = newDate.getDay()
    const diff = newDate.getDate() - day + (day == 0 ? -6 : 1);
    newDate.setDate(diff)
    newDate.setDate(newDate.getDate() + val);

    return newDate;
  }

  const generateUrlFromMonday = (val: number, fromDate: boolean = false) => {

    const newDate = generateDateFromMonday(val)
    if (newDate < dateAtStart()) {
      return
    }

    if (categorySlug) {
      router.replace(routes.indexCategory.url({
        categorySlug,
        calendarDate: newDate.toISOString(),
      }), undefined, {scroll: false})
      return
    }

    router.replace(routes.index.url({
      calendarDate: newDate.toISOString(),
    }), undefined, {scroll: false})
  }


  useEffect(() => {
    if (!sportground || loading || init) {
      return;
    }
    setLoading(true)

    dispatch(getCalendarOpenHours(sportground.id,
      {
        date: calendarDate ? calendarDate as string : date.toISOString(),
        categoryId: sportgroundCategory ? sportgroundCategory.id : null,
      }))
  }, [router.query, sportground,effectValue])

  useEffect(() => {
    if (loading || init) {
      return
    }

    if (categorySlug) {
      router.replace(routes.indexCategory.url({
        categorySlug,
        calendarDate: date.toISOString(),
      }), undefined, {scroll: false})
      return;
    }

    router.replace(routes.index.url({
      calendarDate: date.toISOString(),
    }), undefined, {scroll: false})

  }, [sportground, date])


  useEffect(() => {
    if (init && !data) {
      setInit(false)
      refresh()
    }

    if (init) {
      setInit(false)
      return;
    }

    if (!data) {
      return;
    }
    setLoading(false)
  }, [data])


  return <div className={'calendar'}>
    <CategoryOptionWrapper resourceId={resourceId}
                           category={category}
                           date={date}
                           calendarDate={calendarDate}/>
    <div className={`calendar-category-title`} style={{fontWeight: 700}}> Rezervujte si termín</div>
    <div className={'calendar-wrapper'}>
      <div className={"wrapper"}>
        <div className={'calendar-controls'}>
          <RouteLink
            className={'calendar-controls-route'}
            onClick={() => generateUrl(isMobile ? -1 : -7, true)}>
            <Image className={'my-auto'} style={{height: '10px', transform: 'rotate(180deg)'}}
                   src={'/icons/arrow.svg'} alt={'arrow-left'}/>
            <div className={'my-auto ms-1 ms-md-3 prev-text'} style={{fontWeight: 400}}>
              Předchozí
            </div>
          </RouteLink>
          {Array.from(Array(7)).map((x, i) => {
            const arrayDate = generateDateFromMonday(i);
            const prevDate = generateDateFromMonday(i - 1);
            let active: boolean;
            let prevActive = false;

            if (calendarDate) {
              active = new Date(calendarDate as string).getDate() == arrayDate.getDate();
              prevActive = new Date(calendarDate as string).getDate() == prevDate.getDate()
            } else {
              active = arrayDate.getDate() == new Date().getDate()
              prevActive = prevDate.getDate() == new Date().getDate()
            }

            return <RouteLink
              key={`array-date-value-${arrayDate.toISOString()}`}
              className={`calendar-controls-route ${prevActive || active ? '' : 'hide'} ${active ? 'active-black' : ''} ${arrayDate < dateAtStart() ? 'text-secondary' : ''}`}
              onClick={() => generateUrlFromMonday(i)}>
              {formatDate(generateDateFromMonday(i).toISOString(), 'd. M. y')}
            </RouteLink>
          })}
          <RouteLink
            className={'calendar-controls-route position-relative'}
          >
            <div className={'my-auto me-1 me-md-3 me-lg-3 next-text'} style={{fontWeight: 400}}>
              Následující
            </div>
            <Image className={'my-auto'} style={{height: '10px'}} src={'/icons/arrow.svg'}
                   alt={'arrow-left'}/>
            <div className={'position-absolute top-0 bottom-0 start-0 end-0'}>
              <CalendarPicker date={calendarDate ? new Date(calendarDate as string) : date}
                              setDate={setDate}
                              className={'calendar-next-button cursor-pointer'}
                              setTime={false}/>
            </div>
          </RouteLink>
        </div>
        {loading && <div className={'p-0 position-relative'} style={{minHeight: '40vh'}}><Loader/></div>}
        {!loading && <CalendarHolder/>}
        <div className={"interval-bar legend"}>
          <div className={"wrapper"}>
            <div className={'d-flex place-bar mt-3'} style={{display: 'flex'}}>
              <div className={'my-auto name py-1'}/>
              <div className={'calendar-legend '}>
                {LEGEND.map(en =>
                  <div className={'calendar-legend-box '}>
                    <div className={'calendar-legend-box-dot'} style={{
                      backgroundColor: getCalendarBackground(en),
                    }}/>
                    <div className={'calendar-legend-box-title'}> {getCalendarHoverInformation(en)}</div>
                  </div>,
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {showCreateModal && (
      <DynamicModals.CreateReservationModal
        open={showCreateModal}
        hideModal={() => {
          dispatch(setShowReservationNew(false, null));
          dispatch(resetValidateSlotAvailability())
        }}
      />
    )}
    {showTicketModal && (
      <DynamicModals.TicketModal
        open={showTicketModal}
        hideModal={() => {
          dispatch(setShowTicketModal(false, null));
        }}
      />
    )}
    {showBasket && (
      <DynamicModals.BasketModal
        open={showBasket}
        hideModal={() => {
          dispatch(setShowBasket(false));
          dispatch(setShowTicketModal(true, intervalData));
          dispatch(resetTicketAvailableIntervals())
        }}
      />
    )}
  </div>

}

export default CalendarWrapper;
