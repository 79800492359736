import cx from 'classnames';
import Link from 'next/link';
import React, { HTMLAttributes, MouseEvent, RefObject } from 'react';
import { useConfiguration } from '../../../configuration/useConfiguration';
import { getUrlWithLanguagePrefix } from '../../../lib/i18n/utils';
import { convertUrlToNextUrl } from '../../../routing/utils';
import useRouter from '../../../routing/utils/useRouter';
import useRoutes from '../../../routing/utils/useRoutes';

export interface Props extends Without<HTMLAttributes<HTMLAnchorElement>, 'onClick'> {
  as?: string;
  ref?: RefObject<any>;
  children?: any;
  className?: string;
  disabled?: boolean;
  hideModalOnClick?: boolean;
  onClick?: (e: MouseEvent) => any;
  prefetch?: boolean;
  rel?: string;
  replace?: boolean;
  role?: string;
  route?: string;
  scroll?: boolean;
  shallow?: boolean;
  style?: Record<string, any>;
  tabIndex?: number;
  target?: string;
  title?: string;
  to?: Nullable<string>;
  external?: boolean;
  localized?: boolean; // default true
}

const RouteLink: React.FC<Props> = ({
  disabled,
  to,
  prefetch,
  replace,
  scroll = true,
  shallow,
  route,
  children,
  external,
  onClick,
  tabIndex = 0,
  localized = true,
  ...rest
}) => {
  const { domain } = useConfiguration();

  const routes = useRoutes();
  const router = useRouter();

  let href = (to || route || '').replace('/en/', '/');

  if (router.locale && localized) {
    href = getUrlWithLanguagePrefix(href, domain, router.locale);
  }

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    onClick?.(e);
  };

  const isExternal = external ?? (href && href.charAt(0) != '/');

  const className = cx(rest.className, 'u-cursorPointer');

  if (disabled || (!href && !onClick)) {
    return (
      <a {...rest} className={cx(rest.className, { disabled })} onClick={(e: MouseEvent) => e.preventDefault()}>
        {children}
      </a>
    );
  }

  if (isExternal) {
    return (
      <a href={href} onClick={onClick} {...rest} className={className}>
        {children}
      </a>
    );
  }

  if (onClick) {
    return (
      <a {...rest} className={className} tabIndex={tabIndex} href={href} onClick={handleClick}>
        {children}
      </a>
    );
  }

  return (
    <Link
      {...{ shallow, scroll, prefetch, replace }}
      passHref={false}
      href={convertUrlToNextUrl(routes, href)}
      as={href}
      // locale={locale || router.locale}
    >
      <a {...rest} tabIndex={tabIndex} href={href}>
        {children}
      </a>
    </Link>
  );
};

export default RouteLink;
