import React from 'react';
import { Image } from 'react-bootstrap';
import {v4 as uuidv4} from 'uuid';

interface RatingProps {
  average: number;
}

const max: number = 5;

const Rating: React.FC<RatingProps> = ({ average }) => {
  return (
    <div className={"displayFlex"}>
      {[...Array(max)].map((x, i) => (
        <div key={uuidv4()}>
          {i < Math.floor(average) ? (
            <Image src="/icons/star.svg" className={'lazyload img-fluid'} alt={'star'} width={21} height={20} />
          ) : (
            <Image
              src="/icons/empty_star.svg"
              className={'lazyload img-fluid'}
              alt={'empty star'}
              loading={'lazy'}
              width={23.5}
              height={22.5}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Rating;
