import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {Image} from "react-bootstrap";
import {CalendarIntervalRow, CalendarRow} from "../models";
import {CalendarOpenValue} from "../models/enums";
import {setShowPlaceDetail, setShowReservationNew, setShowTicketModal} from "../actions";
import {checkIfAfter, copyObject, datePlus, getCalendarBackground} from "../helper";
import {formatDate} from "../../../helpers/dateHelper";

interface ComponentProps {
    row: CalendarRow,
    block: any
}

const CalendarRowComponent: React.FC<ComponentProps> = ({row, block}) => {

    const dispatch = useDispatch();


    const getHoverInformation = (interval: CalendarIntervalRow): string | undefined => {
        switch (interval.status) {
            case CalendarOpenValue.OPEN:
                if (new Date() > new Date(interval.timeFrom)) {
                    return 'Uplnynulý termín'
                }
                return 'Volná pozice';
            case CalendarOpenValue.RESERVATION_LONG_TERM:
            case CalendarOpenValue.RESERVATION:
                return 'Rezervace';
            case CalendarOpenValue.SERVICE_RESERVATION:
                return 'Blokováno';
            case CalendarOpenValue.PLACE_CLOSED:
            case CalendarOpenValue.SPORTGROUND_CLOSED:
                return 'Zavřeno';

        }
        return undefined;
    }

    const getFunction = (interval: CalendarIntervalRow, row: CalendarRow, isLastInterval: boolean) => {
        if (interval.status != CalendarOpenValue.OPEN) {
            return;
        }
        if (new Date() > new Date(interval.timeFrom) || new Date(interval.timeFrom) > datePlus(7)) {
            return;
        }

        // reservation modal && process
        if (row.place.enableReservation) {
            dispatch(setShowReservationNew(true, {
                placeId: row.place.id,
                timeFrom: new Date(interval.timeFrom),
                categoryId: row.place.categoryId,
                isLastInterval
            }));
            return;
        }

        if (row.place.enableTicket) {
            dispatch(setShowTicketModal(true, {
                placeId: row.place.id,
                timeFrom: new Date(interval.timeFrom),
                categoryId: row.place.categoryId,
                isLastInterval
            }))
            return
        }
    }


    return <>
        <div className={'place-bar'}>
            <div className={'name place-bar-title'}>
                <div className={'place-bar-title-name'}>
                    {row.place.name}
                </div>
                <Image src="/icons/info-circle.svg"
                       onClick={() => dispatch(setShowPlaceDetail({show: true, placeId: row.place.id}))}
                       className={'lazyload img-fluid ms-1 cursor-pointer'}
                       alt={'info-circle'}
                       width={16}
                       height={16}/>
            </div>
            {row.intervals
                .map((interval, index, data) =>
                    <div
                        className={'item timeline-interval' + (interval.status == CalendarOpenValue.OPEN ? ' cursor-pointer' : '')}
                        key={`calendar-interva-${interval.timeFrom}`}
                        onClick={() => {
                            getFunction(interval, row, index + 1 === data.length);
                        }}
                        style={{
                            // @ts-ignore
                            '--interval': interval.blockLength,
                            backgroundColor: 'cyan',
                            background: getCalendarBackground(interval.status == CalendarOpenValue.OPEN
                            && checkIfAfter(interval, 0) ? CalendarOpenValue.TIME_CLOSED : interval.status),
                        }}
                        title={getHoverInformation(interval)}
                    />,
                )}

        </div>
        <div className={'place-bar place-bar-mobile'}>
            <div className={'my-auto name py-1 d-flex'}>
                <div className={'d-flex flex-column justify-content-center'}>
                    {row.place.name}
                </div>
                <Image src="/icons/info-circle.svg"
                       onClick={() => dispatch(setShowPlaceDetail({show: true, placeId: row.place.id}))}
                       className={'lazyload img-fluid ms-1 cursor-pointer'}
                       alt={'info-circle'}
                       width={16}
                       height={16}/>
            </div>
            <div className={"timeline-intervals timeline-intervals-mobile"}>
                {block.rows.length != 0 && block.rows[0].intervals.map((interval:any, index:any) => {
                    if ((index * interval.blockLength) % 4 !== 0) {
                        return <></>
                    }
                    return <div
                        id={`hour-div-${block.dayOfWeek}-${formatDate(interval.timeFrom, 'HH')}-mobile`}
                        key={`calendar-hour-header-${interval.timeFrom}`}
                        className={'text-center my-auto py-2 timeline-header mb-1'}
                    >
                        {formatDate(interval.timeFrom, 'HH:mm')}
                    </div>
                })}
            </div>
            <div className={"d-flex"}>
                {row.intervals.map((interval, index, data) =>

                    <div
                        className={'item timeline-interval' + (interval.status == CalendarOpenValue.OPEN ? ' cursor-pointer' : '')}
                        key={`calendar-interva-${interval.timeFrom}`}
                        onClick={() => {
                            getFunction(interval, row, index + 1 === data.length);
                        }}
                        style={{
                            // @ts-ignore
                            '--interval': interval.blockLength,
                            backgroundColor: 'cyan',
                            background: getCalendarBackground(interval.status == CalendarOpenValue.OPEN
                            && checkIfAfter(interval, 0) ? CalendarOpenValue.TIME_CLOSED : interval.status),
                        }}
                        title={getHoverInformation(interval)}
                    />,
                )}
            </div>

        </div>
    </>
}

export default CalendarRowComponent;
