import styled from "styled-components";
import {sizes} from "../../../styles/mediaQueries";
import CategoryOptionWrapper from "../components/categories/categoryOptionWrapper";

export const SC = {
  CategoryOptionWrapper: styled.div`


    .category-swiper-wrapper {
      margin-left: 0.7rem;
      display: flex;
      margin-bottom: 2rem;
      overflow: hidden;


      @media (max-width: ${sizes.sDevice}) {
        display: none;
      }
    }

    .slick-track {
      display: flex;
      gap: 1.5rem;

      .slick-slide {
        width: fit-content;
      }
    }

    .first-category-block {
      display: flex;
      cursor: pointer;
      width: 80px !important;
      justify-content: center;
      align-items: center;
      padding-left: 0 !important;
    }

    .category-row {
      color: #000;
      width: 200px;
      text-decoration: none;
      height: 4em;
      display: flex;
      cursor: pointer;
      padding-left: 2rem;

      border: 0.885922px solid #DEDEDE;
      box-sizing: border-box;
      border-radius: 3.54369px;
      font-family: Nunito, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      white-space: nowrap;


      &.active {
        font-weight: bolder;
        border-color: #000;
        background-color: black;
        color: white;

        img {
          filter: invert(1);
        }

      }

      img {
        margin-right: 1.5rem;

        //background-image: url('/icons/sportIcon.svg');
        position: relative;
        max-width: 32px;
        max-height: 32px;
      }
    }

  `,
};
