import {ResourceType, SportgroundCategoryType, SportgroundSettings} from "../../calendar/models/enums";

export interface Location {
  id: string;
  name: string;
  type?: LocationType | null;
}

export enum LocationType {
  MOMC = 'MOMC',
  CITY = 'CITY',
  CITY_PART = 'CITY_PART',
}

export interface FavoriteLocation {
  id: string;
  name: string;
}

export interface Coordinates {
  x: number;
  y: number;
}

export interface Category {
  id: string;
  name: string;
  image?: string;
  url: {
    id: string, target: string
  }
}

export enum Placement {
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR'
}

export interface CategoryFinderPayload {
  findFavorite?: boolean | null;
  sort?: string | null;
  name?: string | null;
  placement?: Placement[],
  types?: string[] | null,
  requiredCapacity?: number | null
}

export interface SportgroundFinderPayload {
  coordinates?: Coordinates | null;
}

export interface Rating {
  overall: number;
  count: number;
  service: number;
  environment: number;
  staff: number;
  average: number;
}

export interface SearchFormData {
  location?: string | null,
  category?: string | null,
  when?: Date | null,
}

export interface SearchAvailableSportgroundForReservationPayload {
  categoryId?: string | null;
  locationId?: string | null;
  localityType?: string | null,
  timeFrom?: string | null;
  timeTo?: string | null;
  date?: string | null;
  length?: number | null;
  coordinates?: Coordinates | null;
}

export interface SportgroundFinderPayload {
  categoryId?: string | null;
  locationId?: string | null;
  localityType?: string | null,
  date?: string | null;
  length?: number | null;
  coordinates?: Coordinates | null;
}

export interface Sportground {
  id: string;
  url: {
    id: string, target: string
  }
  name: string;
  distance: number;
  image: string;
  address: string;
  rating: number,
  priceFrom: number;
  isFavorite: boolean;
  parameters: string[];
  lat: number
  lng: number
}

export interface SearchInformationResponse {
  category?: SearchCategoryInformation | null;
  location?: SearchLocationInformation | null;
}

interface SearchCategoryInformation {
  id: string;
  name: string;
}

interface SearchLocationInformation {
  id: string;
  name: string;
  type: LocationType
}

export interface SearchParametersGroup {
  id: string;
  name: string;
  type: AssignParameterType
  values: SearchParametersGroupValue[]
}

export enum AssignParameterType {
  SPORTGROUND = 'SPORTGROUND',
  PLACE = 'PLACE'
}

export interface SearchParametersGroupValue {
  id: string;
  webId: number;
  name: string;
}

export interface SearchParametersMapValue extends SearchParametersGroupValue {
  type: string;
}

export interface SearchFilter {
  categoryId?: string | null;
  locationId?: string | null;
  localityType?: string | null;
  timeFrom?: string | null;
  timeTo?: string | null;
  date?: string | null;
  length?: number | null;
  lng?: number | null;
  lat?: number | null;
  parameters: string[];
  placeParameters: string[];
}

export enum CustomerFavoriteType {
  CATEGORY = 'CATEGORY',
  CITY = 'CITY',
  CITY_PART = 'CITY_PART',
  DEFAULT_LOCATION = 'DEFAULT_LOCATION',
  SPORTGROUND = 'SPORTGROUND',
}


export enum SporgroundContentType {
  SHORT_DESCRIPTION = 'SHORT_DESCRIPTION',
  LONG_DESCRIPTION = 'LONG_DESCRIPTION',
  MICRO_SITE_ABOUT = 'MICRO_SITE_ABOUT'
}

export enum SporgroundOpeningHourType {
  SHORT_DESCRIPTION = 'SHORT_DESCRIPTION'
}

export enum SporgroundContactConnectionType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  WEBSITE = 'WEBSITE'
}

export enum SportgroundCategoryParameterType {
  PLACE_TYPE = 'PLACE_TYPE',
  PLACE_COUNT = 'PLACE_COUNT'
}

export interface SportgroundDetailData {
  id: string,
  favorite: boolean,
  name: string,
  resources: {// @ts-ignore
    [key:string], value:string}
  seoUrl: string,
  address: SportgroundAddress,
  categories: SportgroundCategory[],
  images: SportgroundImage[],
  contents: Array<{ type: SporgroundContentType, value: string }>,
  parameters: Array<{ name: string, tooltip: string }>,
  openingHours: OpeningHours[],
  contactConnections: Array<{ type: SporgroundContactConnectionType, value: string, note?: string | null }>,
  rating: SportgroundRating
  reviewed: boolean
  settings: Map<SportgroundSettings, string>
}

export interface OpeningHours {
  type: SporgroundOpeningHourType,
  dayOfWeek: number | null,
  timeFrom: string,
  timeTo: string
}

export interface SportgroundAddress {
  id: string,
  street: {
    id: string,
    name: string
  },
  cityPart: {
    id: string,
    name: string,
    city: {
      name: string,
      district: {
        id: string,
        name: string,
        region: {
          id: string, name: string
        }
      }
    }
  },
  descriptiveNumber: number,
  orientationNumber: number,
  momc: {
    id: string,
    name: string
  },
  zip: number
}

export interface SportgroundRating {
  service: number,
  environment: number,
  staff: number,
  average: number,
  count: number
}

export interface SportgroundCategory {
  id: string,
  name: string,
  image: string | null,
  capacity: string | null,
  capacityMin: string | null,
  parameters: SportgroundCategoryParameter[]
  url: { id: string, target: string }
}

export interface SportgroundCategoryParameter {
  type: SportgroundCategoryParameterType,
  value: string
}

export interface SportgroundImage {
  target: string,
  priority: null,
  isPrimary: boolean
}

export interface CategoryDetailData {
  id: string,
  favorite: boolean,
  name: string,
  images: CategoryImage[],
  contents: Array<{ type: CategoryContentType, value: string }>,
  capacityMin?: string | null,
  capacityMax?: string | null,
  parameters: CategoryParameter[],
  types: string[],
}

export interface CategoryParameter {
  value: string,
  type: CategoryParameterType
}

export enum CategoryParameterType {
  PRICE = 'PRICE',
  DIFFICULTY = 'DIFFICULTY',
  FUN = 'FUN',
  MOVEMENT = 'MOVEMENT'

}

export enum CategoryContentType {
  SHORT_DESCRIPTION = 'SHORT_DESCRIPTION',
  LONG_DESCRIPTION = 'LONG_DESCRIPTION'
}

export interface CategoryImage {
  target: string,
  priority: null,
}

export interface CategoryCodebook {
  categoryTypes: CategoryType[],
}

export interface CategoryType {
  id: string,
  name: string,
}

export enum OpenStatus {
  OPEN = 'open', CLOSE = 'close'
}

export interface OpenStatusData {
  status: OpenStatus
  time: string
  dayOfWeek: number
}


export interface SportgroundReviewRow {

  created: string
  serviceRating: number
  enviromentRating: number
  staffRating: number
  text: string
  customer: CustomerReview
}

export interface CustomerReview {
  name: string
  avatar: string
  nickname: string
}
