import dynamic from 'next/dynamic';

const commonParams = {
  loading: () => <></>, // TODO loading
};

const Modals = {
  LoginModal: dynamic(() => import(/* webpackChunkName: "loginModal" */ 'src/modules/ui/components/modals/loginModal'), commonParams),
  AddReviewModal: dynamic(() => import(/* webpackChunkName: "addReviewModal" */ 'src/modules/ui/components/modals/addReviewModal'), commonParams),
  SelectLocalityOnCategoryModal: dynamic(() => import(/* webpackChunkName: "selectLocalityOnCategoryModal" */ 'src/modules/ui/components/modals/selectLocalityOnCategoryModal'), commonParams),
  ConfirmModal: dynamic(() => import(/* webpackChunkName: "confirmModal" */ 'src/modules/ui/components/modals/confirmModal'), commonParams),
  LoadVirtualWalletModal: dynamic(() => import(/* webpackChunkName: "loadVirtualWalletModal" */ 'src/modules/account/components/modals/loadVirtualWalletModal'), commonParams),
  CancelReservationModal: dynamic(() => import(/* webpackChunkName: "loadVirtualWalletModal" */ 'src/modules/account/components/modals/cancelReservationModal'), commonParams),
  EditReservationModal: dynamic(() => import(/* webpackChunkName: "loadVirtualWalletModal" */ 'src/modules/account/components/modals/editReservationModal'), commonParams),
  BasketModal: dynamic(() => import(/* webpackChunkName: "basketModal" */ '../app/components/modals/basketModal'), commonParams),
  PlaceDetailModal: dynamic(() => import(/* webpackChunkName: "basketModal" */ '../app/components/modals/placeDetailModal'), commonParams),
  CreateReservationModal: dynamic(() => import(/* webpackChunkName: "confirmModal" */ 'src/modules/calendar/components/modals/createReservationModal'), commonParams),
  TicketModal: dynamic(() => import(/* webpackChunkName: "ticketModal" */ 'src/modules/calendar/components/modals/ticketModal'), commonParams),
  SelectTimeTicketModal: dynamic(() => import(/* webpackChunkName: "selectTimeTicketModal" */ '../calendar/components/modals/selectTimeTicketModal'), commonParams),
  SelectSeasonTicketModal: dynamic(() => import(/* webpackChunkName: "selectSeasonTicketModal" */ '../calendar/components/modals/selectSeasonTicketModal'), commonParams),
  RentalItemsModal: dynamic(() => import(/* webpackChunkName: "ticketModal" */ 'src/modules/rental/components/modals/rentalItemsModal'), commonParams),
  ConditionDetailModal: dynamic(() => import(/* webpackChunkName: "conditionDetailModal" */ 'src/modules/account/components/modals/conditionDetailModal'), commonParams),

};

export default Modals;
