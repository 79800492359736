import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import useTranslations from "../../../utils/hooks/useTranslations";
import {CalendarBlock} from "../models";
import {selectCalendarOpenHours} from "../reducers/selectors";
import {buildScrollToValue, checkDate} from "../helper";
import {formatDate} from "../../../helpers/dateHelper";
import CalendarRowComponent from "./calendarRowComponent";



const CalendarHolder: React.FC = () => {

    const {ts} = useTranslations();

    const data: CalendarBlock[] | undefined = useSelector(selectCalendarOpenHours);

    useEffect(() => {
        if (undefined == data || !data[0]) {
            return;
        }

        if (!scrollTo || !checkDate(new Date(data[0].date), null)) {
            return
        }
        const divElement = document.getElementById(buildScrollToValue(data[0].dayOfWeek));
        const wrapperDivElemnt = document.getElementById('interval-bar-scroll');

        if (!divElement || !wrapperDivElemnt) {
            return;
        }
        wrapperDivElemnt.scrollLeft =  divElement.offsetLeft;
    }, [data])

    if (data?.length == 0) {
        return <div className={"alert alert-warning"}>Žádné položky nebyly nalezeny</div>
    }

    return <div  className={'calendar-scroll'}>
        <div className={'calendar-holder'} id={'interval-bar-scroll'}>
            {data?.sort((a, b) => a.dayOfWeek - b.dayOfWeek)
                .map((block) => <div className={'position-relative my-2'}>
                    <div className={'interval-bar'}>
                        {block.rows.length == 0 &&
                            <div className={"alert alert-warning calendar-alert-box"}>Sportoviště je v tento den
                                zavřené </div>}
                        <div className={"timeline-intervals timeline-intervals-main"}>
                            <div className={'timeline-header-name'}/>
                            {block.rows.length != 0 && block.rows[0].intervals.map((interval, index) => {
                                if ((index * interval.blockLength) % 4 !== 0) {
                                    return <></>
                                }
                                return <div
                                    id={`hour-div-${block.dayOfWeek}-${formatDate(interval.timeFrom, 'HH')}`}
                                    key={`calendar-hour-header-${interval.timeFrom}`}
                                    className={'text-center my-auto py-2 timeline-header mb-1'}
                                >
                                    {formatDate(interval.timeFrom, 'HH:mm')}
                                </div>

                            })}
                        </div>
                        {block.rows.map(row => <CalendarRowComponent row={row} block={block}/>)}

                    </div>
                </div>)}
        </div>
    </div>
}

export default CalendarHolder;
