import {CalendarOpenValue} from "../models/enums";
import {CalendarIntervalRow} from "../models";
import {formatDate} from "../../../helpers/dateHelper";

export const dateAtStart = () => {
  const start = new Date();
  start.setUTCHours(0, 0, 0, 0);
  return start
}

export const getCalendarBackground = (status: CalendarOpenValue, interval?: CalendarIntervalRow) => {
  switch (status) {
    case CalendarOpenValue.OPEN:
      if (interval && new Date() > new Date(interval.timeFrom)) {
        return '#d8d8d8'
      }
      return '#61cd3c';
    case CalendarOpenValue.SPORTGROUND_CLOSED:
    case CalendarOpenValue.PLACE_CLOSED:
      return '#d8d8d8';
    case CalendarOpenValue.RESERVATION_LONG_TERM:
    case CalendarOpenValue.RESERVATION:
      return '#f5612b';
    case CalendarOpenValue.SERVICE_RESERVATION:
    case CalendarOpenValue.TIME_CLOSED:
      return '#9a9a9a'

  }
}

export const getCalendarHoverInformation = (status: CalendarOpenValue): any => {
  switch (status) {
    case CalendarOpenValue.OPEN:
      return 'Volné';
    case CalendarOpenValue.RESERVATION:
      return 'Obsazené';
    case CalendarOpenValue.SERVICE_RESERVATION:
    case CalendarOpenValue.TIME_CLOSED:
      return 'Blokováno'
    case CalendarOpenValue.SPORTGROUND_CLOSED:
      return 'Nedostupné';
  }
}

export const checkDate = (date1: Date, date2: Date | null):boolean => {
  const checkDate1 = new Date(date1);
  const checkDate2 = date2 ? new Date(date2) : new Date();

  return checkDate1.toDateString() === checkDate2.toDateString()
}

export const copyObject = (block: any): any => {
  return Object.assign({}, {...block});
}

export const checkIfAfter = (interval: CalendarIntervalRow, offset: number = 0) => {

  if (!interval || !interval.timeFrom) {
    return false;
  }

  const offsetDate = new Date();
  offsetDate.setMinutes(new Date().getMinutes() + offset)

  return offsetDate > new Date(interval.timeFrom)
}

export const buildScrollToValue = (dayOfWeek: number): string => {

  const date = new Date();
  date.setHours(date.getHours())
  return `hour-div-${dayOfWeek}-${formatDate(date.toISOString(), 'HH')}-mobile`
}

export const datePlus = (plusDays: number) => {
  const date = new Date()
  date.setDate(date.getDate() + plusDays)
  return date
}
