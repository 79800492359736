import {useCookies} from 'react-cookie';
import {Cookie} from '../../types';
import jwt_decode from 'jwt-decode';
import {Token} from '../../modules/common/models';

export const useMicro = () => {
  const [cookies] = useCookies();

  return {
    resourceId: cookies[Cookie.MICRO_COOKIE],
  };
};
