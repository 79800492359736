import {Action, ActionFunction} from '../../../types';

import createReduxAction from '../../common/actionCreator';
import {
  AvailableIntervalsPayload,
  CalendarIntervalOption,
  GetCalendarOpenHourPayload,
  ReservationData, SeasonTicketFinderPayload,
  ShowPlaceModalData, TicketFinderPayload,
  ValidateSlotAvailabilityPayload
} from '../models';

export function setReservationData(data: ReservationData): Action {
  return {
    type: 'SET_RESERVATION_FORM_DATA',
    data
  };
}

export function getCalendarOpenHours(
  sportgroundId: string,
  data: GetCalendarOpenHourPayload
): ActionFunction<Action> {
  return createReduxAction({
    type: 'CALENDAR_GET_OPEN_HOURS_ACTION',
    promise: ({api}) => api.post('GET_CALENDAR_OPEN_HOUR', {
      urlParts: [sportgroundId]
      , data
    }),
  });
}

export function setShowReservationNew(status: boolean, interval: CalendarIntervalOption | null) {
  return {
    type: 'CALENDAR_OPEN_NEW_RESERVATION_ACTION',
    status,
    interval
  }
}

export function setShowTicketModal(status: boolean, interval: CalendarIntervalOption | null) {
  return {
    type: 'CALENDAR_OPEN_TICKET_MODAL',
    status,
    interval
  }
}

export function setShowBasket(status: boolean) {
  return {
    type: 'SHOW_BASKET_ACTION',
    status
  }
}

export function setShowPlaceDetail(status: ShowPlaceModalData) {
  return {
    type: 'SHOW_PLACE_DETAIL_ACTION',
    status
  }
}


export function validateSlotAvailability(
  sporgroundId: string,
  payload: ValidateSlotAvailabilityPayload
): ActionFunction<Action> {

  return createReduxAction({
    type: 'VALIDATE_SLOT_AVAILABILITY_ACTION',
    promise: ({api}) =>
      api.post('VALIDATE_SLOT_AVAILABILITY', {
        urlParts: [sporgroundId],
        data: payload
      }),
  });
}

export function resetValidateSlotAvailability(): {type:any} {
  return {
    type: 'RESET_VALIDATE_SLOT_AVAILABILITY_ACTION',
  };
}

export function getReservationById(
  reservationId: string,
): ActionFunction<Action> {

  return createReduxAction({
    type: 'GET_RESERVATION_INFO_ACTION',
    promise: ({api}) =>
      api.get('GET_RESERVATION_BY_ID', {
        urlParts: [reservationId],
      }),
  });
}

export function getTickets(
  payload: TicketFinderPayload,
): ActionFunction<Action> {

  return createReduxAction({
    type: 'GET_TICKETS_ACTION',
    promise: ({api}) =>
      api.post('GET_TICKETS', {
        data: payload,
      }),
  });
}

export function getTicketAvailableIntervals(
  ticketId: string,
  payload: AvailableIntervalsPayload,
): ActionFunction<Action> {

  return createReduxAction({
    type: 'GET_TICKET_AVAILABLE_INTERVALS_ACTION',
    promise: ({api}) =>
      api.post('GET_TICKET_AVAILABLE_INTERVALS', {
        urlParts: [ticketId],
        data: payload,
      }),
  });
}

export function resetTicketAvailableIntervals(): {type:any} {

  return {type: 'RESET_TICKET_AVAILABLE_INTERVALS_ACTION'};
}

export function getSeasonTickets(
  payload: SeasonTicketFinderPayload,
): ActionFunction<Action> {

  return createReduxAction({
    type: 'GET_SEASON_TICKETS_ACTION',
    promise: ({api}) =>
      api.post('GET_SEASON_TICKETS', {
        data: payload,
      }),
  });
}
