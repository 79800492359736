import { QueryClient, QueryKey } from 'react-query';
import { dehydrate, DehydratedState } from 'react-query/hydration';
import {SportgroundDetailData} from "../../modules/catalog/models";

type QueryArray = [QueryKey, () => Promise<unknown>];

export const prefetchQueries = async (queries: QueryArray[]): Promise<DehydratedState> => {
  const queryClient = new QueryClient();
  const prefetches = queries.map(([key, fn]) => queryClient.prefetchQuery(key, fn));

  await Promise.all(prefetches);

  return dehydrate(queryClient);
};

export const prefetchInfiniteQueries = async (queries: QueryArray[]): Promise<DehydratedState> => {
  const queryClient = new QueryClient();
  const prefetches = queries.map(([key, fn]) => queryClient.prefetchInfiniteQuery(key, fn));

  await Promise.all(prefetches);

  return dehydrate(queryClient);
};

