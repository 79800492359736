import { State } from 'src/types';

export const selectHomepageCategory = (state: State) => {
  return state.homepage.category;
};

export const selectAroundSportgrounds = (state: State) => {
  return state.homepage.aroundSportgrounds;
};

export const selectTopCities = (state: State) => {
  return state.homepage.topCities;
};

export const selectSearchFormData = (state: State) => {
  return state.homepage.form;
};
