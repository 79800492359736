import styled from "styled-components";
import {sizes} from "../../../styles/mediaQueries";
import {XL_WIDTH_DOWN} from "../../app/constants";

export const SC = {
  FavoriteCategoryBar: styled.div`
    overflow: hidden;
    margin-top: 5rem;
    margin-bottom: 133px;

    @media (max-width: ${sizes.mDevice}) {
      margin: 4rem 0 ;
    }


      h2 {
        font-family: HelveticaNeue, serif;
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 31px;
        padding-bottom: 2rem;
        color: #141126;
        @media (max-width: ${sizes.mDevice}) {
          font-size: 18px;
          font-weight: 100;
          padding-bottom: 0;
        }
    }

    .swiper-wrapper {
      display: flex;
    }

    @media (max-width: ${sizes.mDevice}) {
      h2 {
        font-family: Nunito, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #141126;
        white-space: nowrap;

      }
    }

    .link {
      text-decoration: none;
      font-family: Nunito, serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      color: #141126;

      @media (max-width: ${sizes.mDevice}) {
        margin-bottom: 12px;
      }

    }

    @media (max-width: ${sizes.mDevice}) {
      .link {
        font-family: Nunito, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        text-align: right;
        color: #141126;
      }

    }

    .favoriteList {
      width: 100%;
      height: 100%;
      overflow: visible;

      .swiper-slide {
        opacity: 1;
        margin-right: 24px;

        a {
          color: #000;
          text-decoration: none;
          width: 100%;
          min-width: 10vw;
          height: 4.5em;
          border: 0.885922px solid #DEDEDE;
          box-sizing: border-box;
          border-radius: 3.54369px;
          font-family: Nunito, serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 4.4em;
          white-space: nowrap;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-wrap: normal;




          @media (max-width: ${sizes.mDevice}) {
            min-width: 30vw;
          }

          p {

            @media (max-width: ${sizes.lDevice}) {
              text-wrap: balance
            }

          }


          img {
            max-width: 32px;
            max-height: 32px;
            margin: 2rem;
            @media (max-width: ${sizes.mDevice}) {
              max-width: 15px;
              max-height: 15px;
              margin: 0 1rem;
            }

          }
        }
      }
    }


    @media (max-width: ${sizes.mDevice}) {

      .favoriteSport {
        margin-top: 40px;
        margin-bottom: 27px;


        &__list {

          .sports {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding-left: 0 !important;
            padding-right: 4px !important;

            img {
              position: relative;
              left: -8px;
              bottom: 4px;
              max-width: 25px;
              max-height: 25px;
            }
          }
        }
      }
    }


  `,
};
