import {
  CalendarOpenValue, ColumnBlockType,
  PaymentMethodType,
  ReservationStatusEnum, ResourceType,
  ServiceReservationResourceType,
  ServiceReservationType, SportgroundSettings
} from "./enums";
import {
  OpeningHours, SporgroundContactConnectionType,
  SporgroundContentType,
  SportgroundAddress,
  SportgroundCategory, SportgroundCategoryParameter,
  SportgroundImage, SportgroundRating
} from "../../catalog/models";

export interface ReservationOptions {
  sportground: ReservationOptionsSportground,
  categories: ReservationOptionsCategory[],
  places: ReservationOptionsPlace[],
  options: ReservationOption[],
  paymentMethods: ReservationPaymentMethod[]
}

export interface ReservationOptionsCategory {
  id: string,
  name: string
  min: number,
  max: number
}

export interface ReservationOptionsPlace {
  id: string,
  name: string
}

export interface ReservationOptionsSportground {
  id: string,
  name: string,
  preferredCategory: ReservationOptionsCategory | null
}

export interface ReservationOption {
  id: string,
  categoryId: string,
  name: string,
  values: Array<{
    id: string,
    webId: string,
    value: string
  }>
}

export interface ReservationPaymentMethod {
  id: string,
  name: string,
  image: string,
  type: PaymentMethodType,

}

export interface ValidateSlotAvailabilityPayload {
  placeId: string,
  timeFrom: string,
  timeTo: string,
  date: string
  reservationId?: string
}

export interface AvailableSlot {
  placeId: string,
  placeAvailabilityId: string,
  length: number,
  timeFrom: string,
  timeTo: string,
  date: string,
  price: number,
  slots: Slot
}

export interface Slot {
  timeFrom: string,
  timeTo: string
}

export interface ReservationFilterOptions {
  place?: ReservationOptionsPlace | null,
  length: number,
  when?: Date | null
}


export interface CheaperReservationPrice {
  price: number,
  interval: string
}

export interface ReservationData {
  amount: number,
  when?: Date | null
  place?: ReservationOptionsPlace | null,
  categoryId?: string | null,
  length?: number | null,
  slot?: AvailableSlot | null,
  paymentMethod?: string | null
  name?: string | null,
  image?: string | null,
  phone?: string | null,
  email?: string | null,
  note?: string | null,
  registerCustomer: boolean
  reservationId?: string
}


export interface ReservationResult {
  id: string
  amount: number,
  category: { id: string, name: string, image: string },
  length: number,
  reservationNumber: number,
  redirectUrl?: string | null,
  sportground: { id: string, name: string, image: string },
  timeFrom: string
  timeTo: string
}

export interface ReservationInfo {
  id: string
  isPaid: boolean,
  amount: number,
  category: { id: string, name: string, image: string },
  length: number,
  reservationNumber: number,
  sportground: { id: string, name: string },
  timeFrom: string
  timeTo: string
}

export interface CalendarBlock {
  dayOfWeek: number
  date: string
  rows: CalendarRow[]
}

export interface CalendarRow {
  place: CalendarPlace
  intervals: CalendarIntervalRow[]
}

export interface CalendarIntervalRow {
  timeFrom: string
  timeTo: string
  status: CalendarOpenValue
  blockLength: number
  reservation: CalendarReservation | null
  serviceReservation: CalendarServiceReservation | null

}

export interface CalendarPlace {
  id: string
  categoryId: string,
  name: string,
  enableReservation: boolean,
  enableTicket: boolean
}

export interface GetCalendarOpenHourPayload {
  date: string
  categoryId?: string | null
}


export interface CalendarReservation {
  id: string,
  name: string
  status: ReservationStatusEnum
  reservationNumber: string
  timeFrom: string
  timeTo: string
}


export interface CalendarServiceReservation {
  id: string,
  info: string
  type: ServiceReservationType
  resourceType: ServiceReservationResourceType
  resourceId: string,
  timeFrom: string
  timeTo: string
}


export interface TimeInterval {
  hour: number
  minute: number
}

export interface CancelReservation {
  id: string
  reservationNumber: string
}

export interface CalculateStornoFeeResult {
  hasFee: boolean,
  feeValue: number,
  backValue: number
}

export interface CalendarCodebookSporground {
  openHours: CalendarCodebookOpenHour[]
  openHourExceptions: CalendarCodebookOpenHourException[]
}

export interface CalendarCodebookPlace {
  id: string
  name: string
  categoryId: string
  normal: CalendarCodebookOpenHour[]
}

export interface CalendarCodebookOpenHour {
  timeFrom: string
  timeTo: string
}

export interface ShowPlaceModalData {
  show: boolean
  placeId?: string | null
}

export interface CalendarCodebookOpenHourException extends CalendarCodebookOpenHour {
  note: string
}

export interface CodebookPlace {
  id: string
  name: string
  category: string
  length: number
}



export interface ColumnBlock {
  height: number
  interval: number
  type: ColumnBlockType
}

export interface CalendarIntervalOption {
  timeFrom: Date
  categoryId: string
  placeId: string
  isLastInterval?: boolean
}

export const EmptyCategory = {
  id: 'empty-category',
  name: 'Vše',
  image: null,
  capacity: null,
  capacityMin: null,
  types: [],
  parameters: [],
  url: {id:'empty',target:'empty'},
}

export interface SportgroundDetailDataMicro {
  id: string,
  favorite: boolean,
  seoUrl: string,
  name: string,
  address: SportgroundAddress,
  categories: SportgroundCategory[],
  images: SportgroundImage[],
  contents: Array<{ type: SporgroundContentType, value: string }>,
  parameters: Array<{ name: string, tooltip: string }>,
  openingHours: OpeningHours[],
  contactConnections: Array<{ type: SporgroundContactConnectionType, value: string, note?: string | null }>,
  rating: SportgroundRating
  resources: Map<ResourceType, string>
  settings: Map<SportgroundSettings, string>
  reviewed: boolean
}

export interface TicketRow {
  categoryId: string,
  category: string,
  tickets: Array<{
    id: string,
    name: string,
    prices: Array<{
      id: string,
      name: string,
      price: number,
      priceVat: number,
      vat: {
        id: string,
        name: string,
        value: number
      }
    }>
  }>
}

export interface TicketFinderPayload {
  categoryId?: string | null | undefined,
  sportgroundId?: string | null
}


export interface AvailableIntervalsPayload {
  date: string,
  capacity: number
  sportgroundId: string
}

export interface AvailableIntervalRow {
  lenght: number,
  timeFrom: string,
  timeTo: string,
  slots: Array<{
    timeFrom: string,
    timeTo: string,
  }>
}


export interface SeasonTicketFinderPayload {
  categoryId?: string | null | undefined,
  sportgroundId?: string | null
}

export interface SeasonTicketRow {
  categoryId: string,
  category: string,
  tickets: Array<{
    id: string,
    name: string,
    prices: Array<{
      id: string,
      name: string,
      price: number,
      priceVat: number,
      vat: {
        id: string,
        name: string,
        value: number
      }
    }>
  }>
}



